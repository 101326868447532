import {NotFoundRoute} from 'features/404/components/NotFoundRoute';
import {commonI18nNameSpaces} from 'lib/i18n/commonII18nNameSpaces';
export default NotFoundRoute;

import {GetStaticProps} from 'next';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
export const getStaticProps: GetStaticProps = async ({locale}) => ({
  props: {
    ...(await serverSideTranslations(locale!, [...commonI18nNameSpaces])),
  },
});
